import { ref } from "vue";
import { Usuario } from "../usuarios/usuario";
import { AuthService } from "./auth-service";
import api from "@/lib/api";

export enum AuthState {
    LOADING,
    UNAUTHORIZED,
    AUTHENTICATING,
    AUTHENTICATED
}

export const auth_state = ref<AuthState>(AuthState.LOADING)
export const current_user = ref<Usuario | null>()

export const auth_service = new AuthService(api)

export const refreshAuthState = async () => {
    const user = await auth_service.me()
    if (!user) {
        auth_state.value = AuthState.UNAUTHORIZED
        return
    }
    current_user.value = user
    auth_state.value = AuthState.AUTHENTICATED
}

export const singIn = async (email: string, password: string) => {
    const user = await auth_service.signIn(email, password);
    current_user.value = user
    auth_state.value = AuthState.AUTHENTICATED
}

export const signOut = async () => {
    await auth_service.signOut()
    current_user.value = null
    auth_state.value = AuthState.UNAUTHORIZED
}


refreshAuthState()


