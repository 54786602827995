import { AxiosInstance } from "axios";
import { Usuario } from "../usuarios/usuario";

export type SignInResponse = {
    usuario: Usuario
    access_token: string
    expires_at: number
}

export class AuthService {
    constructor(private api: AxiosInstance) {
    }

    async signIn(email: string, password: string): Promise<Usuario> {
        const { data } = await this.api.post<SignInResponse>('auth/signin', {
            email,
            senha: password
        })
        return data.usuario
    }

    async signOut(): Promise<void> {
        await this.api.post(`auth/signout`)
    }

    async me(): Promise<Usuario | null> {
        try {
            const { data } = await this.api.post<Usuario>(`auth/me`)
            return data
        } catch (error) {
            return null
        }
    }
}